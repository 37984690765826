import RatesGraphWages from '@pretto/bricks/website/rates/components/RatesGraphWages'
import RatesRowWagesText from '@pretto/bricks/website/rates/components/RatesRowWagesText'
import { components, defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import RatesSelectController from '@pretto/website/src/components/Rates/RatesSelectController'
import { BlocRow } from '@pretto/website/src/templates/blocs/components/BlocRow'
import { ControllerProps } from '@pretto/website/src/templates/blocs/types/ControllerProps.type'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { ratesProfile } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesProfile'

type ProfilRowProps = {
  profilRowContent: string
  profilRowDuration?: string
  profilRowIsReversed?: boolean
  profilRowMortgagors?: string
  rates: RatesPayload
  profilRowRegion?: string
  profilRowTitle: string
}

export const ProfilRow: React.FC<ProfilRowProps> = ({
  profilRowContent,
  profilRowDuration,
  profilRowIsReversed,
  profilRowMortgagors,
  rates,
  profilRowRegion,
  profilRowTitle,
  ...props
}) => {
  const graph = {
    component: RatesGraphWages,
    props: (props: ControllerProps) => ratesProfile(rates.marketRatesProfileRegionLatest, profilRowRegion, props),
  }

  const rowProps = {
    overrides: { ...defaultOverrides, Cta: { component: components.Cta, props: { align: 'left' } } },
    content: profilRowContent,
    title: profilRowTitle,
    ...props,
  }

  return (
    <BlocRow isReversed={profilRowIsReversed}>
      <RatesSelectController
        defaultDuration={profilRowDuration ? parseInt(profilRowDuration, 10) : undefined}
        defaultNumberOfMorgagors={profilRowMortgagors ? parseInt(profilRowMortgagors, 10) : undefined}
      >
        {props => <RatesRowWagesText {...rowProps} {...props(graph)} />}
      </RatesSelectController>
    </BlocRow>
  )
}
