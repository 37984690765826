import { RawExtendedRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawExtendedRate'
import { RawRegionRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawRegionRate'

import regions from '../../blocs/rows/rowRates/config/regions'

export const filterDataByMoreCompetitiveRegion = (data: RawRegionRate[] | RawExtendedRate[]) => {
  const averageVeryGoodRateByRegion = regions.map(({ value }) => {
    const regionData = data.filter(data => data.region === value)
    const regionAverageVeryGoodRates =
      regionData.reduce((previous, { veryGoodRate }) => previous + veryGoodRate, 0) / regionData.length
    return { region: value, regionAverageVeryGoodRates }
  })

  const moreCompetitiveRegion = averageVeryGoodRateByRegion.reduce(
    (previous, { region, regionAverageVeryGoodRates }) => {
      if (regionAverageVeryGoodRates < previous.regionAverageVeryGoodRates || !previous.regionAverageVeryGoodRates) {
        return { region, regionAverageVeryGoodRates }
      }
      return previous
    },
    { region: '', regionAverageVeryGoodRates: 0 }
  ).region

  if (!moreCompetitiveRegion) {
    throw new Error('No most competitive region found in filterDataByMoreCompetitiveRegion function')
  }

  return data.filter(({ region }) => region === moreCompetitiveRegion)
}
