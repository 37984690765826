import { useSpring } from '@pretto/bricks/core/utility/animation'

import PropTypes from 'prop-types'
import { useEffect } from 'react'

const AnimatedIncrementingNumber = ({ suffix, value }) => {
  const [animatedValue, animateTo] = useSpring(value, { friction: 10, tension: 60 })

  useEffect(() => {
    animateTo(value)
  }, [value])

  const roundedValue = Math.round(animatedValue * 100) / 100

  return (
    <>
      {roundedValue.toLocaleString('fr', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}
      {suffix && ` ${suffix}`}
    </>
  )
}

AnimatedIncrementingNumber.propTypes = {
  suffix: PropTypes.string,
  value: PropTypes.number.isRequired,
}

export default AnimatedIncrementingNumber
